'use client';

import Link from 'next/link';

import { Button, Icon, Layout, Spacer, Text } from '@voyage-lab/ui';

export function NotFoundPage() {
	const currentFiveSeconds = Math.floor(Date.now() / 5000);
	const randomMessage = messages[currentFiveSeconds % messages.length];

	return (
		<Layout className="h-screen mx-auto grid place-items-center text-center px-8">
			<Layout gap={'4'} display={'flex'} direction={'col'} align={'center'}>
				<Icon name="Cone" className="w-20 h-20 mx-auto" />
				<Text>{String(randomMessage)}</Text>
				<Text variant={'secondary'}>We couldn't find what you're looking for.</Text>
				<Spacer vertical={8} />

				<Link href="/">
					<Button size="lg" variant="outline">
						Return Home
					</Button>
				</Link>
			</Layout>
		</Layout>
	);
}

const messages = [
	'Oops! This page went missing like an abandoned cart.',
	'404! Looks like this page is lost, but we never lose carts.',
	"Uh-oh! This page couldn't be recovered. But we're great at recovering carts!",
	'This page may be gone, but your carts never will be with LiveRecover!',
	"Lost page alert! Looks like this one didn't make it to checkout.",
	"Oops! We couldn't recover this page. Maybe it abandoned us?",
	'404 - We recover carts, but this page seems beyond saving.',
	"Looks like you've found a page that can't be recovered. Let's get you back on track.",
	'Oops! This page is MIA, but your carts are safe with us.',
	"Page not found, but don't worry, we're much better at finding lost carts!",
];
